import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  BoxContent,
  BreakpointBox,
  CheckBoxTermsConditions,
  CircularLoading,
  TitleMoneyText,
} from "../../Atoms";
import {
  DropFileInput,
  GenericInput,
  GenericSelect,
  MessageBox,
  MessageIcon,
} from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { HtmlTextEditor } from "../ReactQuillHtmlEditor";
import { calcTaxesService } from "../../../../services/orderToBuyService";
import useOrders from "../../../../hooks/context/useOrders";
import { CustomModal } from "../Modal";
import { Validation } from "./validationSchema";
import { useCommonData } from "../../../../hooks/useCommonData";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { amber, teal } from "@mui/material/colors";
import axios from "axios";
import { PaymentModalCreditCard } from "../../Molecules/PaymentModalCreditCard";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";

interface Props {
  orderID?: number | null | undefined;
  handleSubmit(val: any): void;
  isLoading?: boolean;
  isFileLoading?: boolean;
  orderType: OrderType;
  verifyPayment(chargeID: string, orderID: number): void;
}

export function NewOrderToBuyBox({
  orderID,
  handleSubmit,
  verifyPayment,
  isLoading,
  isFileLoading,
  orderType,
}: Props) {
  const { orderModal } = useCommonData();
  const { JPYmoney } = useJPYmoney();
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { getUserLocalStorage, language, siteConfig } = useAuth();
  const { validationSchema } = Validation(language);
  const { user } = getUserLocalStorage();
  const { order, sendNewMessage, readAllMessages, setKeepAttMessages } =
    useOrders();
  const { strings } = useTranslate();
  const [messageModal, setMessageModal] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState(true);
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false);

  const formik = useFormik<ICreateOrderToBuy>({
    initialValues: {
      totalAuctionPrice: 0,
      auctionStatus: 0,
      title: "",
      description: "",
      price: 0,
      priceXqtd: 0,
      itemsQuantity: 0,
      paymentChoice: "wise",
      serviceTax: 0,
      subTotal: 0,
      discount: 0,
      refundValue: 0,
      userCreditUsed: 0,
      useMyCredits: false,
      paymentTax: 0,
      totalPrice: 0,
      status: "ordered",
      userID: user?.user?.id,
      userName: user?.user?.name,
      suite: user?.suite[0].id,
      files: [],
      termsConditions: false,
      orderType,
      totalPix: 0,
      chargeID: "",
      stripeSession: "",
      paymentIntent: "",
    },
    validationSchema,
    async onSubmit(values: ICreateOrderToBuy) {
      if (values?.status === "waitingPayment") {
        handleSubmit({
          type: "createFiles",
          data: {
            files: values.files,
            suite: values.suite,
            orderID: order?.id,
            creditPaidAll: values.userCreditUsed > 0 && values.totalPrice === 0,
          },
        });
        formik.resetForm();
      } else {
        if (order?.id) {
          handleSubmit({
            type: "update",
            data: { ...values },
          });
        } else {
          handleSubmit({
            type: "create",
            data: { ...values },
          });
        }
      }
      formik.resetForm();
    },
  });

  const limitSizeFiles = useMemo(() => {
    const verifyLimite =
      formik.values.files.reduce((acc: any, next: any) => acc + next?.size, 0) /
      1024;
    return verifyLimite;
  }, [formik.values.files]);

  useEffect(() => {
    // controla se o modal vai ser de cadastro ou edição
    formik.setFieldValue("title", order?.title ?? "");
    formik.setFieldValue("description", order?.description ?? "");
    formik.setFieldValue("price", order?.price ?? 0);
    formik.setFieldValue("itemsQuantity", order?.itemsQuantity ?? 0);
    formik.setFieldValue("paymentChoice", order?.paymentChoice ?? "wise");
    formik.setFieldValue("serviceTax", order?.serviceTax ?? 0);
    formik.setFieldValue("subTotal", order?.subTotal ?? 0);
    formik.setFieldValue("discount", order?.discount ?? 0);
    formik.setFieldValue("userCreditUsed", order?.userCreditUsed ?? 0);
    formik.setFieldValue(
      "useMyCredits",
      order?.userCreditUsed ? (order?.userCreditUsed > 0 ? true : false) : false
    );
    formik.setFieldValue("paymentTax", order?.paymentTax ?? 0);
    formik.setFieldValue("totalPrice", order?.totalPrice ?? 0);
    formik.setFieldValue("status", order?.status ?? "ordered");
    formik.setFieldValue("userID", user.user.id ?? 0);
    formik.setFieldValue("userName", user.user.name ?? "");
    formik.setFieldValue("termsConditions", order?.id ? true : false);
    formik.setFieldValue("chargeID", order?.chargeID);
    formik.setFieldValue("stripeSession", order?.stripeSession);
    formik.setFieldValue("paymentIntent", order?.paymentIntent);
    formik.setFieldValue("totalAuctionPrice", order?.totalAuctionPrice);

    setTimeout(() => {
      setMessageTooltip(false);
    }, 5000);
    // eslint-disable-next-line
  }, [order, orderID]);

  const getTaxes = async () => {
    if (
      formik.values.price > 0 &&
      formik.values.itemsQuantity > 0 &&
      orderType
    ) {
      if (orderType === "auction" && order?.auctionStatus === 4) {
        const response = await calcTaxesService(
          formik.values.useMyCredits,
          formik.values.discount,
          formik.values.userID,
          formik.values.totalAuctionPrice,
          formik.values.paymentChoice,
          formik.values.itemsQuantity,
          orderType,
          order?.id
        );
        formik.setFieldValue("priceXqtd", response?.taxes?.priceXqtd);
        formik.setFieldValue("serviceTax", response?.taxes?.serviceTax);
        formik.setFieldValue("subTotal", response?.taxes?.subTotal);
        formik.setFieldValue("userCreditUsed", response?.taxes?.creditsUsed);
        formik.setFieldValue("paymentTax", response?.taxes?.paymentTax);
        formik.setFieldValue("totalPrice", response?.taxes?.totalPrice);
      }

      if (orderType !== "auction") {
        const response = await calcTaxesService(
          formik.values.useMyCredits,
          formik.values.discount,
          formik.values.userID,
          formik.values.price,
          formik.values.paymentChoice,
          formik.values.itemsQuantity,
          orderType,
          order?.id
        );
        formik.setFieldValue("priceXqtd", response?.taxes?.priceXqtd);
        formik.setFieldValue("serviceTax", response?.taxes?.serviceTax);
        formik.setFieldValue("subTotal", response?.taxes?.subTotal);
        formik.setFieldValue("userCreditUsed", response?.taxes?.creditsUsed);
        formik.setFieldValue("paymentTax", response?.taxes?.paymentTax);
        formik.setFieldValue("totalPrice", response?.taxes?.totalPrice);
      }
    }
  };

  const getPix = async () => {
    if (formik.values?.paymentChoice === "pix") {
      const jpyXBRL = await axios.get(
        "https://economia.awesomeapi.com.br/last/JPY-BRL"
      );
      const pix = (
        formik.values.totalPrice * jpyXBRL?.data?.JPYBRL?.high
      ).toFixed(2);

      formik.setFieldValue("totalPix", Number(pix));
    }
  };

  useEffect(() => {
    getPix();
    // eslint-disable-next-line
  }, [formik.values.totalPrice]);

  useEffect(() => {
    if (formik.values.price > 0 && formik.values.itemsQuantity > 0) {
      getTaxes();
    }

    // eslint-disable-next-line
  }, [
    order,
    formik.values.price,
    formik.values.paymentChoice,
    formik.values.useMyCredits,
    formik.values.itemsQuantity,
  ]);

  useEffect(() => {
    setKeepAttMessages(true);

    if (orderType === "askQuestion") {
      formik.setFieldValue("price", siteConfig?.askProductPrice);
    }
    if (orderType === "bid") {
      formik.setFieldValue("price", siteConfig?.offerPrice);
    }

    if (orderType === "auction") {
      formik.setFieldValue("itemsQuantity", 1);
    }

    return () => setKeepAttMessages(false);
    // eslint-disable-next-line
  }, [orderType]);

  const disableInputs =
    orderID && user?.user?.role !== "admin" && order?.id ? true : false;

  const showSubmitButton = () => {
    // !disableInputs ||
    //   order?.status === "waitingPayment" ||
    //   ((order?.PaymentVoucher?.length as any) === 0

    if (!disableInputs) {
      return true;
    }
    if ((order?.PaymentVoucher?.length as any) > 0) {
      return false;
    }
    if (order?.status === "waitingPayment") {
      return true;
    }
  };

  const hasNewMessage = () => {
    const messages = order?.WarningMessage as any;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const showProof = () => {
    if (!order?.status) return false;
    if (order?.userCreditUsed > 0 && order?.totalPrice === 0) return false;
    if (order?.paymentChoice === "card") return false;
    if (order?.status === "ordered") return false;
    if (order?.status === "waitingPayment") return true;
    if (order?.PaymentVoucher?.length === 0) return false;
    return true;
  };

  const disablePriceInput = () => {
    if (!["orderToBuy", "auction"].includes(orderType as any)) return true;
    if (disableInputs) return true;
    return false;
  };

  const getInfoPaymentChoice = () => {
    switch (formik.values.paymentChoice) {
      case "wise":
        return orderModal.wisePayment;
      case "paypal":
        return orderModal.paypalPayment;
      case "pix":
        return orderModal.pixPayment;
      case "card":
        return orderModal.cardPayment;
      default:
        return "";
    }
  };

  const steps = [
    strings.newOrderPage.steps.ordered[language],
    strings.newOrderPage.steps.auctionInProgress[language],
  ].concat(
    order?.auctionStatus === 3
      ? strings.newOrderPage.steps.auctionLost[language]
      : `${strings.newOrderPage.steps.auctionWin[language]} by ${JPYmoney(
          order?.totalAuctionPrice as any
        )}`
  );

  const showAuctionWaitMessage = () => {
    if (orderID && orderType === "auction" && order?.auctionStatus === 4)
      return true;
    return false;
  };

  const auctionSteps = {
    0: 0,
    1: 1,
    2: 2,
    3: 2,
    4: 3,
  };

  const ResumePaymentTax = () => (
    <Box width={"97%"}>
      {[
        { title: orderModal.price, price: formik.values.priceXqtd },
        {
          title: orderModal.serviceTax,
          price: formik.values.serviceTax,
        },
        { title: orderModal.discount, price: formik.values.discount },
        { title: orderModal.subTotal, price: formik.values.subTotal },
        {
          title: orderModal.useCredit,
          price: formik.values.userCreditUsed,
          hasSwitch: true,
        },
        {
          title: orderModal.paymentTax,
          price: formik.values.paymentTax,
        },
        { title: orderModal.total, price: formik.values.totalPrice },
      ].map(({ price, title, hasSwitch }, index) => (
        <TitleMoneyText
          // show={orderID ? true : false}
          key={index}
          price={price}
          title={title}
          hasSwitch={hasSwitch}
          disabled={order?.id ? true : false}
          switchValue={formik.values.useMyCredits}
          onChangeSwitch={
            hasSwitch
              ? (val) => {
                  formik.setFieldValue("useMyCredits", val);
                }
              : () => {}
          }
        />
      ))}

      {formik.values.paymentChoice === "pix" && (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            mb={1}
            mt={1}
          >
            <Typography>BRL (reais)</Typography>
            <Typography>{formik.values.totalPix}</Typography>
          </Box>
          <Divider />
        </>
      )}
    </Box>
  );

  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            {order?.id && (
              <MessageIcon
                hasNewMessage={hasNewMessage()}
                openModal={() => setMessageModal(true)}
                tooltipOpen={messageTooltip}
                text={orderModal.tooltip}
                chatButton
              />
            )}

            <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
          </Box>
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              formik?.touched?.title && formik?.errors?.title ? true : false
            }
            errorMessage={formik?.touched?.title ? formik?.errors?.title : ""}
            value={formik?.values?.title}
            placeholder={orderModal.title}
            inputName="title"
            disabled={disableInputs}
          />

          <HtmlTextEditor
            onChange={(val) => formik.setFieldValue("description", val)}
            value={formik.values.description}
            disabled={disableInputs}
          />

          <BreakpointBox props={{ mt: isMobile ? 8 : 5 }}>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.price && formik?.errors?.price ? true : false
              }
              errorMessage={formik?.touched?.price ? formik?.errors?.price : ""}
              value={formik?.values?.price}
              placeholder={
                orderType === "auction" ? "Max bid ¥" : orderModal.price
              }
              inputName="price"
              inputType="number"
              disabled={disablePriceInput()}
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.itemsQuantity && formik?.errors?.itemsQuantity
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.itemsQuantity
                  ? formik?.errors?.itemsQuantity
                  : ""
              }
              value={formik?.values?.itemsQuantity}
              placeholder={orderModal.quantity}
              inputName="itemsQuantity"
              inputType="number"
              disabled={disableInputs || orderType === "auction"}
            />
            <GenericSelect
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.paymentChoice && formik?.errors?.paymentChoice
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.paymentChoice
                  ? formik?.errors?.paymentChoice
                  : ""
              }
              value={formik?.values?.paymentChoice}
              placeholder={orderModal.payment}
              name="paymentChoice"
              options={[
                { label: "Transferwise", value: "wise" },
                { label: "Pix (Brasil)", value: "pix" },
                { label: "PayPal", value: "paypal" },
                { label: "Credit Card", value: "card" },
              ]}
              disabled={disableInputs}
            />
          </BreakpointBox>
          {order?.status === "waitingPayment" && (
            <FormHelperText sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}>
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              {getInfoPaymentChoice()}
            </FormHelperText>
          )}

          {orderID && orderType === "auction" && (
            <Box sx={{ width: "100%", mt: 3 }}>
              <Stepper
                activeStep={
                  auctionSteps[
                    order?.auctionStatus as keyof typeof auctionSteps
                  ]
                }
                alternativeLabel
              >
                {steps.map((label, index) => {
                  if (index === 2 && order?.auctionStatus === 3) {
                    return (
                      <Step key={label}>
                        <StepLabel error>{label}</StepLabel>
                      </Step>
                    );
                  } else if (order?.auctionStatus === 4 && index === 2) {
                    return (
                      <Step
                        key={label}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: teal[500], // circle color (COMPLETED)
                          },
                        }}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  } else {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  }
                })}
              </Stepper>
            </Box>
          )}
          {orderType !== "auction" && <ResumePaymentTax />}
          {showAuctionWaitMessage() ? (
            <ResumePaymentTax />
          ) : !orderID ? (
            <FormHelperText
              sx={{ bgcolor: amber[100], borderRadius: 2, p: 1, mt: 3, mb: 1 }}
            >
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              {strings.newOrderPage.auctionWarning[language]}
            </FormHelperText>
          ) : orderType === "auction" ? (
            <Typography mt={3}>
              {strings.newOrderPage.auctionWait[language]}
            </Typography>
          ) : (
            <></>
          )}
          <CheckBoxTermsConditions
            checked={formik.values.termsConditions}
            error={
              formik?.touched?.termsConditions &&
              formik?.errors?.termsConditions
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.termsConditions
                ? formik?.errors?.termsConditions
                : ""
            }
            disabled={order?.id ? true : false}
            setChecked={(check) =>
              formik.setFieldValue("termsConditions", check)
            }
          />

          {order?.status === "processingPayment" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                Payment has not been fully processed, please click below to
                verify payment
              </FormHelperText>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth
                onClick={() => verifyPayment(order?.chargeID, order?.id)}
              >
                Verify payment
              </Button>
            </>
          )}
          {order?.status === "notPaid" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[600], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                The payment process was not concluded, please contact your card
                issuer or try again
              </FormHelperText>
              <Button
                sx={{ mt: 3 }}
                fullWidth
                variant="contained"
                onClick={() => {
                  setCreditCardModalOpen(true);
                }}
              >
                Click here to pay with credit card
              </Button>
            </>
          )}
          {showProof() && (
            <>
              <Typography variant="h1" mb={3} mt={3}>
                {orderModal.proofPayment}
              </Typography>
              {limitSizeFiles >= 500 && (
                <Typography variant="h2" color={"red"} mb={1}>
                  Max file size reached
                </Typography>
              )}
              {isFileLoading ? (
                <CircularLoading />
              ) : (
                <DropFileInput
                  onFileChange={(files) => formik.setFieldValue("files", files)}
                  oldFiles={order?.PaymentVoucher ?? []}
                />
              )}
            </>
          )}
          {order?.paymentChoice === "card" &&
            order?.status === "waitingPayment" && (
              <Button
                sx={{ mt: 3 }}
                fullWidth
                variant="contained"
                onClick={() => {
                  setCreditCardModalOpen(true);
                }}
              >
                Click here to pay with credit card
              </Button>
            )}
          {showSubmitButton() && order?.paymentChoice !== "card" && (
            <Button
              sx={{ mt: 3 }}
              fullWidth
              variant="contained"
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={
                disableInputs && order?.status !== "waitingPayment"
                  ? true
                  : false
              }
            >
              {order?.status === "waitingPayment"
                ? "Send payment"
                : order?.id
                ? "Update"
                : "Create"}
            </Button>
          )}
        </>
      )}

      <CustomModal
        openModal={messageModal}
        setOpenModal={setMessageModal}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <MessageBox
          data={order?.WarningMessage}
          sendMessage={(newMessage) =>
            sendNewMessage({
              description: newMessage,
              orderID: order?.id,
              type: user.user.role,
              userID: user.user.id,
              messageType: "orderToBuy",
            })
          }
          readMessages={() => readAllMessages(order?.id)}
        />
      </CustomModal>

      <PaymentModalCreditCard
        open={creditCardModalOpen}
        setOpen={() => setCreditCardModalOpen(false)}
        itensInfo={{
          itemID: order?.id ?? 0,
          totalValue:
            order?.orderType === "auction"
              ? order?.totalAuctionPrice + order?.paymentTax
              : order?.totalPrice ?? 0,
          type: "orderToBuy",
        }}
      />
    </BoxContent>
  );
}
