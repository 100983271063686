import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import {
  BoxContent,
  BreakpointBox,
  CheckBoxTermsConditions,
  CircularLoading,
} from "../../Atoms";
import { green, amber, red, grey, blue, teal } from "@mui/material/colors";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  DropFileInput,
  GenericInput,
  GenericSelect,
  MessageBox,
  MessageIcon,
} from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useFormik } from "formik";

import { useEffect, useMemo, useState } from "react";
import { HtmlTextEditor } from "../ReactQuillHtmlEditor";
import { calcTaxesService } from "../../../../services/orderToBuyService";
import useOrdersAdmin from "../../../../hooks/context/useOrdersAdmin";
import { CustomModal } from "../Modal";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { Validation } from "../NewOrderToBuyBox/validationSchema";
import { useCommonData } from "../../../../hooks/useCommonData";
import axios from "axios";
import { useTranslate } from "../../../../hooks/useTranslate";

interface Props {
  orderID?: number | null | undefined;
  handleSubmit(val: any): void;
  isLoading?: boolean;
  isFileLoading?: boolean;
  orderType: OrderType;
  verifyPayment(chargeID: string, orderID: number): void;
}

export function EditOrderAdminBox({
  orderID,
  handleSubmit,
  isLoading,
  isFileLoading,
  orderType,
  verifyPayment,
}: Props) {
  const { orderModal, adminButtons, labelStatus, adminOrder } = useCommonData();
  const { strings } = useTranslate();
  const { JPYmoney } = useJPYmoney();
  const { user, language } = useAuth();
  const { validationSchema } = Validation(language);
  const {
    order,
    sendNewMessage,
    readAllMessages,
    setKeepAttMessages,
    fileRemoveAdmin,
  } = useOrdersAdmin();
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ status: "", open: false });
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const [messageTooltip, setMessageTooltip] = useState(true);
  const isAdmin = user?.user?.role === "admin";
  const actions = {
    waitingPayment: labelStatus.waitingPayment.label,
    cancelOrder: labelStatus.cancelOrder.label,
    orderDone: labelStatus.orderDone.label,
    cancelOrderWithRefund: labelStatus.cancelOrderWithRefund.label,
    auctionLost: strings.newOrderPage.steps.auctionLost[language],
    auctionWin: strings.newOrderPage.steps.auctionWin[language],
    auctionInProgress: strings.newOrderPage.steps.auctionInProgress[language],
  };

  const formik = useFormik<ICreateOrderToBuy>({
    initialValues: {
      totalAuctionPrice: 0,
      auctionStatus: 0,
      title: "",
      description: "",
      price: 0,
      priceXqtd: 0,
      itemsQuantity: 0,
      paymentChoice: "wise",
      serviceTax: 0,
      subTotal: 0,
      discount: 0,
      userCreditUsed: 0,
      useMyCredits: false,
      paymentTax: 0,
      totalPrice: 0,
      refundValue: 0,
      status: "ordered",
      userID: order?.usersId,
      userName: user?.user?.name,
      suite: user?.suite[0].id,
      files: [],
      termsConditions: false,
      orderType,
      totalPix: 0,
    },
    validationSchema,
    async onSubmit(values: ICreateOrderToBuy) {
      handleSubmit({
        type: "update",
        data: { ...values, id: orderID },
      });
    },
  });

  const limitSizeFiles = useMemo(() => {
    const verifyLimite =
      formik.values.files.reduce((acc: any, next: any) => acc + next?.size, 0) /
      1024;
    return verifyLimite;
  }, [formik.values.files]);

  useEffect(() => {
    if (order?.id) {
      formik.setFieldValue("title", order?.title);
      formik.setFieldValue("description", order?.description);
      formik.setFieldValue("price", order?.price);
      formik.setFieldValue("itemsQuantity", order?.itemsQuantity);
      formik.setFieldValue("paymentChoice", order?.paymentChoice);
      formik.setFieldValue("serviceTax", order?.serviceTax);
      formik.setFieldValue("subTotal", order?.subTotal);
      formik.setFieldValue("discount", order?.discount);
      formik.setFieldValue("userCreditUsed", order?.userCreditUsed);
      formik.setFieldValue(
        "useMyCredits",
        order?.userCreditUsed
          ? order?.userCreditUsed > 0
            ? true
            : false
          : false
      );
      formik.setFieldValue("paymentTax", order?.paymentTax);
      formik.setFieldValue("totalPrice", order?.totalPrice);
      formik.setFieldValue("status", order?.status);
      formik.setFieldValue("userID", order?.usersId);
      formik.setFieldValue("termsConditions", order?.id ? true : false);
      formik.setFieldValue("totalAuctionPrice", order?.totalAuctionPrice);
      formik.setFieldValue("auctionStatus", order?.auctionStatus);
    }

    setTimeout(() => {
      setMessageTooltip(false);
    }, 5000);
    // eslint-disable-next-line
  }, [order?.id]);

  const getTaxes = async () => {
    if (
      formik.values.price > 0 &&
      formik.values.itemsQuantity > 0 &&
      orderType
    ) {
      if (orderType === "auction" && order?.auctionStatus === 4) {
        const response = await calcTaxesService(
          formik.values.useMyCredits,
          formik.values.discount,
          formik.values.userID,
          formik.values.totalAuctionPrice,
          formik.values.paymentChoice,
          formik.values.itemsQuantity,
          orderType,
          order?.id
        );
        formik.setFieldValue("priceXqtd", response?.taxes?.priceXqtd);
        formik.setFieldValue("serviceTax", response?.taxes?.serviceTax);
        formik.setFieldValue("subTotal", response?.taxes?.subTotal);
        formik.setFieldValue("userCreditUsed", response?.taxes?.creditsUsed);
        formik.setFieldValue("paymentTax", response?.taxes?.paymentTax);
        formik.setFieldValue("totalPrice", response?.taxes?.totalPrice);
      }

      if (orderType !== "auction" && orderID) {
        const response = await calcTaxesService(
          formik.values.useMyCredits,
          formik.values.discount,
          formik.values.userID,
          formik.values.price,
          formik.values.paymentChoice,
          formik.values.itemsQuantity,
          orderType,
          order?.id
        );
        formik.setFieldValue("priceXqtd", response?.taxes?.priceXqtd);
        formik.setFieldValue("serviceTax", response?.taxes?.serviceTax);
        formik.setFieldValue("subTotal", response?.taxes?.subTotal);
        formik.setFieldValue("userCreditUsed", response?.taxes?.creditsUsed);
        formik.setFieldValue("paymentTax", response?.taxes?.paymentTax);
        formik.setFieldValue("totalPrice", response?.taxes?.totalPrice);
      }
    }
  };

  const getPix = async () => {
    try {
      if (order?.paymentChoice === "pix") {
        const jpyXBRL = await axios.get(
          "https://economia.awesomeapi.com.br/last/JPY-BRL"
        );
        const pix = (
          formik.values.totalPrice * jpyXBRL?.data?.JPYBRL?.high
        ).toFixed(2);

        formik.setFieldValue("totalPix", Number(pix));
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    getPix();
    // eslint-disable-next-line
  }, [formik.values.totalPrice]);

  useEffect(() => {
    if (order.id) {
      getTaxes();
    }
    // eslint-disable-next-line
  }, [
    order,
    formik.values.price,
    formik.values.paymentChoice,
    formik.values.useMyCredits,
    formik.values.discount,
    formik.values.itemsQuantity,
    formik.values.status,
  ]);

  useEffect(() => {
    setKeepAttMessages(true);

    // if (orderType !== "orderToBuy") {
    //   formik.setFieldValue("price", 300);
    // }

    return () => setKeepAttMessages(false);
    // eslint-disable-next-line
  }, [order?.id]);

  const hasNewMessage = () => {
    const messages = order?.WarningMessage as any;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const confirmAction = () => {
    if (
      ["auctionLost", "auctionWin", "auctionInProgress"].includes(
        confirmModal.status
      )
    ) {
      const changeToNumber = {
        auctionInProgress: 1,
        auctionLost: 3,
        auctionWin: 4,
      };
      formik.setFieldValue(
        "auctionStatus",
        Number(
          changeToNumber[confirmModal.status as keyof typeof changeToNumber]
        )
      );
      if (confirmModal.status === "auctionWin") {
        formik.setFieldValue("status", "waitingPayment");
      }
      formik.handleSubmit();
    } else {
      formik.setFieldValue("status", confirmModal.status);
      formik.handleSubmit();
    }
    setConfirmModal(() => ({ open: false, status: "" }));
  };

  const showProof = () => {
    if (!order?.status) return false;
    if (order?.userCreditUsed > 0 && order?.totalPrice === 0) return false;
    if (order?.status === "ordered") return false;
    if (order?.paymentChoice === "card") return false;
    if (order?.status === "waitingPayment") return true;
    if (order?.PaymentVoucher?.length === 0) return false;
    return true;
  };

  const showBtns = () => {
    if (order.status === "orderDone") return false;
    if (order.status === "cancelOrder") return false;
    if (order.status === "cancelOrderWithRefund") return false;
    // if (
    //   order.orderType === "auction" &&
    //   ![2, 3, 4].includes(order.auctionStatus)
    // )
    //   return false;
    return true;
  };

  const disableInputs =
    (user?.user?.role !== "admin" && order?.id) ||
    order?.status === "orderDone" ||
    order.status === "cancelOrder" ||
    order.status === "cancelOrderWithRefund"
      ? true
      : false;

  const disablePriceInput = () => {
    if (!["orderToBuy", "auction"].includes(orderType as any)) return true;
    if (disableInputs) return true;
    return false;
  };

  const getInfoPaymentChoice = () => {
    switch (formik.values.paymentChoice) {
      case "wise":
        return orderModal.wisePayment;
      case "paypal":
        return orderModal.paypalPayment;
      case "pix":
        return orderModal.pixPayment;
      default:
        return "";
    }
  };

  const steps = [
    strings.newOrderPage.steps.ordered[language],
    strings.newOrderPage.steps.auctionInProgress[language],
  ].concat(
    order?.auctionStatus === 3
      ? strings.newOrderPage.steps.auctionLost[language]
      : `${strings.newOrderPage.steps.auctionWin[language]} by ${JPYmoney(
          order?.totalAuctionPrice as any
        )}`
  );

  const showAuctionWaitMessage = () => {
    if (orderID && orderType === "auction" && order?.auctionStatus === 4)
      return true;
    return false;
  };

  const auctionSteps = {
    0: 0,
    1: 1,
    2: 2,
    3: 2,
    4: 3,
  };

  const ResumePaymentTax = () => (
    <Box width={"97%"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.price}</Typography>
        <Typography>{JPYmoney(formik.values.priceXqtd)}</Typography>
      </Box>
      <Divider />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.serviceTax}</Typography>
        <Typography>{JPYmoney(formik.values.serviceTax)}</Typography>
      </Box>
      <Divider />

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.discount}</Typography>
        <TextField
          id="filled-basic"
          variant="filled"
          label="Desconto ￥"
          sx={{ maxWidth: 100 }}
          size="small"
          disabled={disableInputs}
          value={formik.values.discount}
          onChange={(e) =>
            formik.setFieldValue("discount", Number(e.target.value))
          }
        />
      </Box>
      <Divider />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.subTotal}</Typography>
        <Typography>{JPYmoney(formik.values.subTotal)}</Typography>
      </Box>

      <Divider />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.useCredit}</Typography>

        <Typography>{JPYmoney(formik.values.userCreditUsed)}</Typography>
      </Box>
      <Divider />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.paymentTax}</Typography>
        <Typography>{JPYmoney(formik.values.paymentTax)}</Typography>
      </Box>
      <Divider />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Typography>{orderModal.total}</Typography>
        <Typography>{JPYmoney(formik.values.totalPrice)}</Typography>
      </Box>

      {formik.values.paymentChoice === "pix" && (
        <>
          <Divider />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            mb={1}
            mt={1}
          >
            <Typography>BRL (reais)</Typography>
            <Typography>{formik.values.totalPix}</Typography>
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            <MessageIcon
              hasNewMessage={hasNewMessage()}
              openModal={() => setMessageModal(true)}
              tooltipOpen={messageTooltip}
              text={orderModal.tooltip}
              chatButton
            />
            <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
          </Box>
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              formik?.touched?.title && formik?.errors?.title ? true : false
            }
            errorMessage={formik?.touched?.title ? formik?.errors?.title : ""}
            value={formik?.values?.title}
            placeholder="Title"
            inputName="title"
            disabled={disableInputs}
          />
          <HtmlTextEditor
            onChange={(val) => formik.setFieldValue("description", val)}
            value={formik.values.description}
            disabled
          />

          <BreakpointBox props={{ mt: isMobile ? 8 : 5 }}>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.price && formik?.errors?.price ? true : false
              }
              errorMessage={formik?.touched?.price ? formik?.errors?.price : ""}
              value={formik?.values?.price}
              placeholder={
                orderType === "auction" ? "Max bid ¥" : orderModal.price
              }
              inputName="price"
              inputType="number"
              disabled={disablePriceInput()}
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.itemsQuantity && formik?.errors?.itemsQuantity
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.itemsQuantity
                  ? formik?.errors?.itemsQuantity
                  : ""
              }
              value={formik?.values?.itemsQuantity}
              placeholder={orderModal.quantity}
              inputName="itemsQuantity"
              inputType="number"
              disabled={disableInputs || orderType === "auction"}
            />
            <GenericSelect
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.paymentChoice && formik?.errors?.paymentChoice
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.paymentChoice
                  ? formik?.errors?.paymentChoice
                  : ""
              }
              value={formik?.values?.paymentChoice ?? ""}
              placeholder="Select payment"
              name="paymentChoice"
              options={[
                { label: "Transferwise", value: "wise" },
                { label: "Pix (Brasil)", value: "pix" },
                { label: "PayPal", value: "paypal" },
                { label: "Credit Card", value: "card" },
              ]}
              disabled={disableInputs}
            />
          </BreakpointBox>

          {order?.status === "waitingPayment" && (
            <FormHelperText sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}>
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              {getInfoPaymentChoice()}
            </FormHelperText>
          )}

          {orderID && orderType === "auction" && (
            <Box sx={{ width: "100%", mt: 3 }}>
              <Stepper
                activeStep={
                  auctionSteps[
                    order?.auctionStatus as keyof typeof auctionSteps
                  ]
                }
                alternativeLabel
              >
                {steps.map((label, index) => {
                  if (index === 2 && order?.auctionStatus === 3) {
                    return (
                      <Step key={label}>
                        <StepLabel error>{label}</StepLabel>
                      </Step>
                    );
                  } else if (order?.auctionStatus === 4 && index === 2) {
                    return (
                      <Step
                        key={label}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: teal[500], // circle color (COMPLETED)
                          },
                        }}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  } else {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  }
                })}
              </Stepper>
            </Box>
          )}
          {orderType !== "auction" && <ResumePaymentTax />}
          {showAuctionWaitMessage() ? (
            <ResumePaymentTax />
          ) : orderType === "auction" ? (
            <Typography mt={3}>
              {strings.newOrderPage.auctionWait[language]}
            </Typography>
          ) : (
            <></>
          )}

          <CheckBoxTermsConditions
            checked={formik.values.termsConditions}
            error={
              formik?.touched?.termsConditions &&
              formik?.errors?.termsConditions
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.termsConditions
                ? formik?.errors?.termsConditions
                : ""
            }
            disabled={order?.id ? true : false}
            setChecked={(check) =>
              formik.setFieldValue("termsConditions", check)
            }
          />

          {order?.status === "processingPayment" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                Payment has not been fully processed, please click below to
                verify payment
              </FormHelperText>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth
                onClick={() => verifyPayment(order?.chargeID, order?.id)}
              >
                Verify payment
              </Button>
            </>
          )}
          {isAdmin && order?.paymentIntent !== "" && (
            <Box
              sx={{
                border: "1px solid grey",
                borderRadius: 2,
                mb: 2,
                mt: 2,
                p: 1,
              }}
            >
              <Typography textAlign={"center"}>Pay by credit card</Typography>
              <Typography>Payment intent: {order?.paymentIntent} </Typography>
              <Typography sx={{ wordBreak: "break-all" }}>
                Charge: {order?.chargeID}
              </Typography>
            </Box>
          )}
          {showProof() && (
            <>
              <Typography variant="h1" mb={3}>
                Proof of payment
              </Typography>
              {limitSizeFiles >= 500 && (
                <Typography variant="h2" color={"red"} mb={1}>
                  Max file size reached
                </Typography>
              )}
              {isFileLoading ? (
                <CircularLoading />
              ) : (
                <DropFileInput
                  onFileChange={(files) => formik.setFieldValue("files", files)}
                  oldFiles={order?.PaymentVoucher ?? []}
                  fileRemoveAdmin={fileRemoveAdmin}
                />
              )}
            </>
          )}

          {showBtns() && (
            <>
              <Button
                sx={{
                  mt: 3,
                  bgcolor: amber[900],
                  "&:hover": { bgcolor: amber[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() =>
                  setConfirmModal({ status: "waitingPayment", open: true })
                }
              >
                {adminButtons.waitingPayment}
              </Button>

              <Button
                sx={{
                  mt: 3,
                  bgcolor: green[700],
                  "&:hover": { bgcolor: green[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() =>
                  setConfirmModal({ status: "orderDone", open: true })
                }
              >
                {adminButtons.orderDone}
              </Button>

              <Button
                sx={{
                  mt: 3,
                  bgcolor: red[500],
                  "&:hover": { bgcolor: red[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() =>
                  setConfirmModal({ status: "cancelOrder", open: true })
                }
              >
                {adminButtons.cancelOrder}
              </Button>

              <Button
                sx={{
                  mt: 3,
                  bgcolor: red[900],
                  "&:hover": { bgcolor: red[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() =>
                  setConfirmModal({
                    status: "cancelOrderWithRefund",
                    open: true,
                  })
                }
              >
                {adminButtons.cancelOrderWithRefund}
              </Button>
            </>
          )}

          {showBtns() && order.orderType === "auction" && (
            <>
              <Typography
                mt={3}
                textAlign={"center"}
                sx={{
                  background: grey[200],
                  p: 1,
                  width: "100%",
                  borderRadius: 5,
                  fontWeight: 500,
                }}
              >
                Auction options
              </Typography>
              <Box display={"flex"} gap={1} width={"100%"}>
                <Button
                  sx={{
                    mt: 3,
                    bgcolor: blue[300],
                    height: 50,
                    "&:hover": { bgcolor: blue[200] },
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    setConfirmModal({
                      status: "auctionInProgress",
                      open: true,
                    })
                  }
                >
                  In progress
                </Button>
                <Button
                  sx={{
                    mt: 3,
                    bgcolor: red[900],
                    "&:hover": { bgcolor: red[200] },
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    setConfirmModal({
                      status: "auctionLost",
                      open: true,
                    })
                  }
                >
                  Lost
                </Button>

                <Button
                  sx={{
                    mt: 3,
                    bgcolor: teal[300],
                    "&:hover": { bgcolor: teal[200] },
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    setConfirmModal({
                      status: "auctionWin",
                      open: true,
                    })
                  }
                >
                  Win
                </Button>
              </Box>
            </>
          )}
        </>
      )}

      <CustomModal
        openModal={messageModal}
        setOpenModal={setMessageModal}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <MessageBox
          data={order?.WarningMessage}
          sendMessage={(newMessage) =>
            sendNewMessage({
              description: newMessage,
              orderID: order?.id,
              type: user.user.role,
              userID: user.user.id,
              messageType: "orderToBuy",
            })
          }
          readMessages={() => readAllMessages(order?.id)}
        />
      </CustomModal>

      <CustomModal
        openModal={confirmModal.open}
        setOpenModal={() => setConfirmModal((old) => ({ ...old, open: false }))}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography mb={2} width={315} sx={{ wordBreak: "break-word" }}>
              {adminOrder.changeStatus} "
              {actions[confirmModal.status as keyof typeof actions]}" ?
            </Typography>
            <Typography
              sx={{ wordBreak: "break-word" }}
              width={320}
              mb={2}
              color="red"
            >
              {confirmModal.status === "cancelOrder" &&
                adminOrder.canceledError}

              {confirmModal.status === "cancelOrderWithRefund" &&
                adminOrder.canceledErrorFullValue}
            </Typography>
            {confirmModal.status === "auctionWin" && (
              <TextField
                id="filled-basic"
                variant="filled"
                label="Total auction value ￥"
                sx={{ maxWidth: 150, mb: 3 }}
                size="small"
                value={formik.values.totalAuctionPrice}
                onChange={(e) => {
                  if (e.target.value.match(new RegExp("^[0-9]*$"))) {
                    formik.setFieldValue(
                      "totalAuctionPrice",
                      Number(e.target.value)
                    );
                  }
                }}
              />
            )}
            {confirmModal.status === "cancelOrderWithRefund" && (
              <>
                <Typography variant="h1">{orderModal.choiceRefund}</Typography>
                <Box
                  display="flex"
                  width={320}
                  justifyContent={"space-evenly"}
                  flexWrap={"wrap"}
                  gap={2}
                  p={2}
                >
                  <Box sx={{ width: "40%" }}>
                    <Typography textAlign={"center"}>Credits</Typography>
                    <Button
                      sx={{ width: "100%" }}
                      variant="outlined"
                      onClick={() =>
                        formik.setFieldValue(
                          "refundValue",
                          formik.values.userCreditUsed
                        )
                      }
                    >
                      {JPYmoney(formik.values.userCreditUsed)}
                    </Button>
                  </Box>
                  <Box sx={{ width: "40%" }}>
                    <Typography textAlign={"center"}>Order price</Typography>
                    <Button
                      sx={{ width: "100%" }}
                      variant="outlined"
                      onClick={() =>
                        formik.setFieldValue(
                          "refundValue",
                          formik.values.totalPrice
                        )
                      }
                    >
                      {JPYmoney(formik.values.totalPrice)}
                    </Button>
                  </Box>
                  <Box sx={{ width: "40%" }}>
                    <Typography textAlign={"center"}>
                      Order + Credits
                    </Typography>
                    <Button
                      sx={{ width: "100%" }}
                      variant="outlined"
                      onClick={() =>
                        formik.setFieldValue(
                          "refundValue",
                          formik.values.totalPrice +
                            formik.values.userCreditUsed
                        )
                      }
                    >
                      {JPYmoney(
                        formik.values.totalPrice + formik.values.userCreditUsed
                      )}
                    </Button>
                  </Box>
                </Box>
                <TextField
                  id="filled-basic"
                  variant="filled"
                  label="Total refund ￥"
                  sx={{ maxWidth: 150, mb: 3 }}
                  size="small"
                  value={formik.values.refundValue}
                  onChange={(e) =>
                    formik.setFieldValue("refundValue", Number(e.target.value))
                  }
                  type="number"
                />
              </>
            )}
            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={confirmAction}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() =>
                  setConfirmModal((old) => ({ ...old, open: false }))
                }
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </BoxContent>
  );
}
