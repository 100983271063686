import { api } from "./api";

export const createShippmentService = async (data: CreateShippment) => {
  const response2 = await api.post("/create-deliverie", { data });
  return response2.data;
};

export const chargeShippmentByCardSerice = async (
  data: ChargeCardOrderToBuy
) => {
  const response2 = await api.post("/charge-shippment-card", {
    shippmentID: data.itemID,
    totalValue: data.totalValue,
  });
  return response2.data;
};

export const verifyShippmentPayment = async (
  chargeID: string,
  shippmentID: number
) => {
  const response2 = await api.post("/verify-payment-shippment", {
    sessionid: chargeID,
    shippmentID,
  });
  return response2.data;
};

export const getShippmentsService = async (
  userID: number,
  filter: {
    key: string;
    searchTerm: string;
    itensPerPage: number;
    offset: number;
  }
) => {
  const response2 = await api.get("/find-deliveries", {
    params: {
      userID: userID,
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
    },
  });
  return response2.data;
};

export const findOneShippment = async (deliverieID: number) => {
  const response2 = await api.get("/find-one-deliverie", {
    params: { deliverieID },
  });
  return response2.data;
};

export const updateCreditsDeliverieService = async (data: any) => {
  const response2 = await api.post("/credits-deliverie", { data });
  return response2.data;
};

export const updateProductInShippment = async (data: any) => {
  const response2 = await api.post("/update-deliverie", { data });
  return response2.data;
};

export const deleteProductInShippment = async (data: any) => {
  const response2 = await api.post("/delete-product-from-deliverie", { data });
  return response2.data;
};

export const addProductInShippment = async (data: any) => {
  const response2 = await api.post("/add-product-deliverie", { data });
  return response2.data;
};

export const deleteShippment = async (deliverieID: any) => {
  const response2 = await api.delete("/delete-deliverie", {
    params: { deliverieID },
  });
  return response2.data;
};

export const uploadServiceShippment = async (
  data: any[],
  deliverieID: number
) => {
  // await new Promise((resolve, reject) => {
  try {
    await Promise.all(
      data.map(async (item) => {
        await api.post(
          "/upload-deliverie-proof",
          { file: item, deliverieID },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
    );
    // resolve(true);
  } catch (err: any) {
    throw new Error(err);
  }
  // });
};
